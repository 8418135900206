import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import BGImg from 'gatsby-background-image'

import './index.css'

import Layout from '../components/layout'
import SEO from '../components/seo'

const DatenschutzPage = () => {
  const data = useStaticQuery(graphql`
    query {
      lock: file(relativePath: { eq: "datenschutz/datenschutz.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title='CSU Neutraubling' />
      <BGImg
        className='jumbotron jumbotron-fluid'
        style={{ backgroundPosition: 'top center' }}
        fluid={data.lock.childImageSharp.fluid}>
        <div className='container'>
          <h1
            className='display-4 text-light mobile-header'
            style={{ textShadow: '0 0 10px black', fontWeight: 'normal' }}>
            Datenschutz
          </h1>
        </div>
      </BGImg>
      <div className='container my-5'>
        <h2>Datenschutzerklärung</h2>
        <p>
          Die Betreiber dieser Seiten nehmen den Schutz Ihrer personenbezogenen
          Daten ernst. Ihre personenbezogenen Daten werden vertraulich und
          entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
          Datenschutzerklärung behandelt. Zu den personenbezogenen Daten gehören
          zum Beispiel Name, IP-Adresse, Postanschrift oder E-Mail-Adresse.
        </p>
        <p>
          Im Folgenden möchten wir Sie – auch im Sinne der Art. 13, 14 der
          Datenschutzgrundverordnung (DSGVO) – darüber informieren, in welcher
          Weise auf dieser Seite personenbezogene Daten von Ihnen erfragt und
          diese elektronisch gespeichert werden.
        </p>
        <h3>1. Datensicherheit </h3>
        <p>
          Diese Seite nutzt aus Gründen der Sicherheit und zum Schutz der
          Übertragung vertraulicher Inhalte, wie zum Beispiel der Anfragen, die
          Sie an uns als Seitenbetreiber senden, eine SSL-Verschlüsselung. Eine
          verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des
          Browsers von „http://“ auf „https://“ wechselt und an dem
          Schloss-Symbol in Ihrer Browserzeile.
        </p>
        <p>
          Wenn die SSL Verschlüsselung aktiviert ist, können die Daten, die Sie
          an uns übermitteln, nicht von Dritten mitgelesen werden.
        </p>
        <p>
          Wir weisen aber vorsichtshalber darauf hin, dass die Datenübertragung
          im Internet Sicherheitslücken aufweisen kann. Ein lückenloser Schutz
          der Daten vor dem Zugriff durch Dritte ist praktisch nicht möglich.
        </p>
        <h3>2. Erhebung und Verarbeitung von Server-Logfile-Daten </h3>
        <p>
          Der Websitebetreiber bzw. Seitenprovider erhebt Daten über Zugriffe
          auf die Seite und speichert diese als „Server-Logfiles“ ab. Folgende
          Daten, die einzeln oder in Kombination auch Personenbezug aufweisen
          können, können so protokolliert werden: besuchte Website; Uhrzeit zum
          Zeitpunkt des Zugriffes; Menge der gesendeten Daten in Byte;
          Quelle/Verweis, von welchem Sie auf die Seite gelangten; verwendeter
          Browser; verwendetes Betriebssystem; verwendete IP-Adresse.
        </p>
        <p>
          Die erhobenen Daten dienen lediglich statistischen Auswertungen und
          zur Verbesserung der Website. Der Websitebetreiber behält sich
          allerdings vor, die Server-Logfiles nachträglich zu überprüfen,
          sollten konkrete Anhaltspunkte auf eine rechtswidrige Nutzung
          hinweisen. Falls in den Webseiten Inhalte Dritter, bspw. von YouTube,
          Google Maps, etc. eingebunden werden, wird ggfs. Ihre IP-Adresse an
          den Drittanbieter übertragen.
        </p>
        <h3>3. Erhebung und Verarbeitung weiterer personenbezogener Daten </h3>
        <p>Weitere personenbezogene Daten werden von uns nur erhoben,</p>
        <ul>
          <li>wenn Sie uns diese freiwillig mitteilen;</li>
          <li>aufgrund gesetzlicher Verpflichtung;</li>
          <li>
            soweit dies in dieser Datenschutzerklärung gesondert aufgeführt
            wird.
          </li>
        </ul>
        <p>
          Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 DSGVO,
          insbesondere lit. a), c) und f). Der Betreiber darf außerdem nach § 15
          Abs. 3 Telemediengesetz für Zwecke der Werbung, der Marktforschung
          oder zur bedarfsgerechten Gestaltung der Seite Nutzungsprofile bei
          Verwendung von Pseudonymen erstellen, sofern der Nutzer dem nicht
          widerspricht. Diese Nutzungsprofile dürfen nicht mit Daten über den
          Träger des Pseudonyms zusammengeführt werden.
        </p>
        <p>
          Angaben, die Sie bei Eintragung in unser Kontaktformular machen,
          werden zur weiteren Bearbeitung und für den Fall von Anschlussfragen
          gespeichert.
        </p>
        <p>
          Ihre Angaben werden für bis zu fünf Jahre gespeichert. Der
          Websitebetreiber bietet Ihnen außerdem einen Newsletter an, in welchem
          er Sie über aktuelle Geschehnisse und Angebote informiert. Möchten Sie
          den Newsletter abonnieren, müssen Sie eine gültige E-Mail-Adresse
          angeben. Um Sie persönlich ansprechen zu können, wird auch um Ihren
          Namen und Ihre Anschrift gebeten. Diese Angaben sind freiwillig. Ihre
          Einwilligung zur Speicherung Ihrer Daten sowie zum Bezug des
          Newsletters können Sie jederzeit widerrufen. Der Widerruf kann über
          einen Link in den Newslettern selbst oder per Email an eine der unter
          11. genannten Kontaktmöglichkeiten erfolgen.
        </p>
        <h3>4. Cookies </h3>
        <p>
          Auf unseren Webseiten verwenden wir an mehreren Stellen sog.
          „Cookies“. Cookies sind kleine Textdateien, die auf Ihrem Rechner
          abgelegt werden und die Ihr Browser auf Ihrem Computer speichert. Sie
          dienen dazu, unser Angebot nutzerfreundlicher, effektiver und sicherer
          zu machen.
        </p>
        <p>
          Die von uns verwendeten Cookies sind so genannte „Domain-Cookies“.
          Diese Cookies werden, wenn sie bei der Registrierung die Einstellung
          gewählt haben „Angemeldet bleiben“ auf Ihrem Rechner gespeichert.
          Dieses Vorgehen erleichtert Ihnen die Nutzung unserer Webanwendung, da
          Sie sich nicht bei jedem erneuten Besuch neu anmelden müssen. Diese
          Cookies richten auf Ihrem Rechner keinen Schaden an und enthalten
          keine Viren.
        </p>
        <p>
          Wenn Sie keine Cookies annehmen wollen, können Sie dies durch eine
          entsprechende Einstellung Ihres Internet-Browsers verhindern. Dadurch
          kann die Nutzung der Webseiten allerdings eingeschränkt sein.
        </p>
        <p>
          Insbesondere wird Google Analytics eingesetzt, ein Webanalysedienst
          der Google Inc. („Google“). Die Benutzung erfolgt auf der Grundlage
          des Art. 6 Abs. 1 S. 1 lit. f. DSGVO. Google Analytics verwendet sog.
          „Cookies“, Textdateien, die auf Ihrem Computer gespeichert werden und
          die eine Analyse der Benutzung der Webseite durch Sie ermöglichen. Die
          durch den Cookie erzeugten Informationen über Ihre Benutzung der
          Webseite wie
        </p>
        <ul>
          <li>Browser-Typ/-Version,</li>
          <li>verwendetes Betriebssystem,</li>
          <li>Referrer-URL (die zuvor besuchte Seite),</li>
          <li>Hostname des zugreifenden Rechners (IP-Adresse),</li>
          <li>Uhrzeit der Serveranfrage,</li>
        </ul>
        werden in der Regel an einen Server von Google in den USA übertragen und
        dort gespeichert. Die im Rahmen von Google Analytics von Ihrem Browser
        übermittelte IP-Adresse wird nicht mit anderen Daten von Google
        zusammengeführt. Wir haben zudem auf dieser Webseite Google Analytics um
        den Code „anonymizeIP“ erweitert. Dies garantiert die Maskierung Ihrer
        IP-Adresse, sodass alle Daten anonym erhoben werden. Nur in
        Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in
        den USA übertragen und dort gekürzt.
        <p>
          Im Auftrag des Betreibers dieser Website wird Google diese Information
          benutzen, um Ihre Nutzung der Webseite auszuwerten, um Reports über
          die Webseiten-Aktivitäten zusammenzustellen und um weitere mit der
          Webseiten-Nutzung und der Internetnutzung verbundene Dienstleistungen
          gegenüber dem Webseitenbetreiber zu erbringen. Sie können die
          Speicherung der Cookies durch eine entsprechende Einstellung Ihrer
          Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass
          Sie in diesem Fall möglicherweise nicht sämtliche Funktionen dieser
          Webseite vollumfänglich werden nutzen können.
        </p>
        <p>
          Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten
          und auf Ihre Nutzung der Webseite bezogenen Daten (inkl. Ihrer
          IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google
          verhindern, indem sie das unter dem folgenden Link verfügbare
          Browser-Plugin herunterladen und installieren:{' '}
          <a
            href='http://tools.google.com/dlpage/gaoptout?hl=de'
            target='_blank'>
            http://tools.google.com/dlpage/gaoptout?hl=de
          </a>
          . Alternativ zum Browser-Add-On, insbesondere bei Browsern auf mobilen
          Endgeräten, können Sie die Erfassung durch Google Analytics zudem
          verhindern, indem Sie auf diesen Link klicken. Es wird ein
          Opt-Out-Cookie gesetzt, das die zukünftige Erfassung Ihrer Daten beim
          Besuch dieser Website verhindert. Der Opt-Out-Cookie gilt nur in
          diesem Browser und nur für unsere Website und wird auf Ihrem Gerät
          abgelegt. Löschen Sie die Cookies in diesem Browser, müssen Sie das
          Opt-Out-Cookie erneut setzen.
        </p>
        <p>
          Wir nutzen Google Analytics weiterhin dazu, Daten aus
          Double-Click-Cookies und auch AdWords zu statistischen Zwecken
          auszuwerten. Sollten Sie dies nicht wünschen, können Sie dies über den
          Anzeigenvorgaben-Manager (
          <a
            href='http://www.google.com/settings/ads/onweb/?hl=de'
            target='_blank'>
            http://www.google.com/settings/ads/onweb/?hl=de
          </a>
          ) deaktivieren.
        </p>
        <p>
          Weitere Informationen zum Datenschutz im Zusammenhang mit Google
          Analytics finden Sie etwa in der Google Analytics-Hilfe (
          <a
            href='https://support.google.com/analytics/answer/6004245?hl=de'
            target='_blank'>
            https://support.google.com/analytics/answer/6004245?hl=de
          </a>
          ).
        </p>
        <h3>5. Social Plugins </h3>
        a) Verwendung von Facebook Social-Plugins
        <p>
          Auf unseren Webseiten verwenden wir einen Plugin zum sozialen Netzwerk
          Facebook. Facebook ist ein Unternehmen der Facebook Inc., 1601 S.
          California Ave, Palo Alto, CA 94304, USA. Detaillierte Hinweise zu den
          Funktionen der einzelnen Plugins und deren Erscheinungsbild finden Sie
          auf der folgenden Webseite:{' '}
          <a
            href='http://developers.facebook.com/docs/plugins/'
            target='_blank'>
            http://developers.facebook.com/docs/plugins/
          </a>
          .
        </p>
        <p>
          Der Plugin zu Facebook verbirgt sich hinter dem zunächst ausgegrauten
          Button mit dem „f – Empfehlen“-Zeichen. Durch Klick auf diesen Button
          können Sie eine Verbindung zu Facebook aufbauen. Wir möchten Sie
          darauf hinweisen, dass mit dem Klick auf diesen Button, Facebook ggfs.
          Informationen und persönliche Daten (Ihre IP-Adresse) in Erfahrung
          bringt und speichert. Näheres entnehmen Sie bitte den
          Datenschutzhinweisen von Facebook (s.u.).
        </p>
        <p>
          Anders als bei Verwendung der klassischen Facebook-Plugins wird nicht
          bereits bei Aufruf unserer Webseite eine Verbindung zu Facebook
          aufgebaut, sondern erst wenn Sie mit einem Klick auf den Button das
          Plugin aktivieren. Der Button wird farbig. Dann baut Ihr Browser eine
          direkte Verbindung mit den Servern von Facebook auf. Der Inhalt des
          Plugins wird von Facebook direkt an Ihren Browser übermittelt und von
          diesem in die Webseite eingebunden. Der Anbieter hat daher keinen
          Einfluss auf den Umfang der Daten, die Facebook mit Hilfe dieses
          Plugins erhebt und informiert die Nutzer daher entsprechend seinem
          Kenntnisstand:
        </p>
        <p>
          Durch die Einbindung der Plugins erhält Facebook die Information, dass
          ein Nutzer die entsprechende Seite des Angebots aufgerufen hat. Ist
          der Nutzer bei Facebook eingeloggt, kann Facebook den Besuch seinem
          Facebook-Konto zuordnen. Wenn Nutzer mit den Plugins interagieren, zum
          Beispiel den Like Button betätigen oder einen Kommentar abgeben, wird
          die entsprechende Information von Ihrem Browser direkt an Facebook
          übermittelt und dort gespeichert. Falls ein Nutzer kein Mitglied von
          Facebook ist, besteht trotzdem die Möglichkeit, dass Facebook seine
          IP-Adresse in Erfahrung bringt und speichert. Laut Facebook wird in
          Deutschland nur eine anonymisierte IP-Adresse gespeichert.
        </p>
        <p>
          Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und
          Nutzung der Daten durch Facebook sowie die diesbezüglichen Rechte und
          Einstellungsmöglichkeiten zum Schutz der Privatsphäre der Nutzer,
          können diese den Datenschutzhinweisen von Facebook entnehmen:
          <a href='https://www.facebook.com/about/privacy/' target='_blank'>
            {' '}
            https://www.facebook.com/about/privacy/
          </a>
          .
        </p>
        <p>
          Wenn ein Nutzer Facebookmitglied ist und nicht möchte, dass Facebook
          über dieses Angebot Daten über ihn sammelt und mit seinen bei Facebook
          gespeicherten Mitgliedsdaten verknüpft, muss er sich vor dem Besuch
          des Internetauftritts bei Facebook ausloggen.
        </p>
        <p>
          Ebenfalls ist es möglich Facebook-Social-Plugins mit Add-ons für Ihren
          Browser zu blocken, zum Beispiel mit dem „Facebook Blocker“.
        </p>
        b) Verwendung von Twitter Social-Plugins
        <p>
          Eingebunden ist auch eine Share-Funktionalität des Internetdienstes
          Twitter Inc., 795 Folsom St., Suite 600, San Francisco, CA 94107, USA.
          Die Twitter-Plugins erkennen Sie an dem Twitter-Logo. Eine Übersicht
          über die Funktion der Twitter-Plugins finden Sie hier:{' '}
          <a href='http://dev.twitter.com' target='_blank'>
            http://dev.twitter.com
          </a>
          .
        </p>
        <p>
          Die Share-Funktionalität verbirgt sich hinter dem ausgegrauten
          Vogelsymbol Tweet. Wenn Sie mit Klick auf den Button die
          Funktionalität aktivieren, baut Ihr Browser eine direkte Verbindung
          mit den Servern von Twitter auf. Der Anbieter hat daher keinen
          Einfluss auf den Umfang der Daten, die Twitter mit Hilfe dieses
          Plugins erhebt und informiert die Nutzer entsprechend seinem
          Kenntnisstand. Nach diesem wird lediglich die IP-Adresse des Nutzers
          die URL der jeweiligen Webseite beim Bezug des Buttons mit
          übermittelt, aber nicht für andere Zwecke, als die Darstellung des
          Buttons, genutzt. Weitere Informationen hierzu finden sich in der
          Datenschutzerklärung von Twitter unter twitter.com/privacy.
        </p>
        <h3>6. Google Maps</h3>
        <p>
          Die CSU-Webseiten und die CSU-Apps benutzen Google Maps. Google hält
          dafür eine Datenschutzerklärung bereit. Einzelheiten erfahren Sie
          unter{' '}
          <a
            href='https://www.google.com/intl/de/policies/privacy/'
            target='_blank'>
            https://www.google.com/intl/de/policies/privacy/
          </a>
          .
        </p>
        <h3>7. Weitergabe personenbezogener Daten an Dritte </h3>
        <p>
          Ihre personenbezogenen Daten werden an Dritte nur weitergegeben oder
          sonst übermittelt,
        </p>
        <ul>
          <li>
            wenn dies zum Zwecke der Bearbeitung Ihrer Eingaben erforderlich ist
            (z.B. bei Webdienstleistern);
          </li>
          <li>
            sofern dazu eine rechtliche Verpflichtung besteht (z.B. bei
            Behörden);
          </li>
          <li>oder Sie zuvor ausdrücklich eingewilligt haben.</li>
        </ul>
        <p>
          Der Umfang der übermittelten Daten beschränkt sich grundsätzlich auf
          das erforderliche Minimum.
        </p>
        <h3>8. Widerruf von Einwilligungen, Widerspruch </h3>
        <p>
          Wir weisen Sie darauf hin, dass Sie Ihre Einwilligungen jederzeit ohne
          Angaben von Gründen mit Wirkung für die Zukunft widerrufen können.{' '}
        </p>
        <h3>9. Auskunftsrecht und Löschung </h3>
        <p>
          Sie haben das Recht, von dem Verantwortlichen eine Bestätigung darüber
          zu verlangen, ob Sie betreffende personenbezogene Daten verarbeitet
          werden. Ist dies der Fall, so haben Sie ein Recht auf Auskunft über
          diese personenbezogenen Daten und auf die in Art. 15 DSGVO im
          Einzelnen aufgeführten Informationen.
        </p>
        <p>
          Sie haben das Recht, von dem Verantwortlichen unverzüglich die
          Berichtigung Sie betreffender unrichtiger personenbezogener Daten und
          ggf. die Vervollständigung unvollständiger personenbezogener Daten zu
          verlangen (Art. 16 DSGVO).
        </p>
        <p>
          Sie haben das Recht, von dem Verantwortlichen zu verlangen, dass sie
          betreffende personenbezogene Daten unverzüglich gelöscht werden,
          sofern einer der in Art. 17 DSGVO im einzelnen aufgeführten Gründe
          zutrifft, z. B. wenn die Daten für die verfolgten Zwecke nicht mehr
          benötigt werden (Recht auf Löschung). Der Verantwortliche verarbeitet
          die personenbezogenen Daten dann nicht mehr, es sei denn, er kann
          zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die
          die Interessen, Rechte und Freiheiten der betroffenen Person
          überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung
          oder Verteidigung von Rechtsansprüchen (Art. 21 DSGVO).
        </p>
        <p>
          Sie haben das Recht, von dem Verantwortlichen die Einschränkung der
          Verarbeitung zu verlangen, wenn eine der in Art. 18 DSGVO aufgeführten
          Voraussetzungen gegeben ist, z. B. wenn Sie Widerspruch gegen die
          Verarbeitung eingelegt hat, für die Dauer der Prüfung durch den
          Verantwortlichen.
        </p>
        <h3>10. Änderungen dieser Datenschutzbestimmungen </h3>
        <p>
          bleiben vorbehalten, etwa bei Änderungen der Gesetzeslage oder
          technischen Veränderungen der Website.
        </p>
        <h3>11. Kontakt </h3>
        <p>
          In Belangen des Datenschutzes wenden Sie sich bitte an den Betreiber
          der Seite (siehe Impressum).
        </p>
        <p>
          Ihre Rechte können Sie auch gegenüber dem Datenschutzbeauftragten der
          Christlich-Sozialen Union in Bayern e.V., derzeit Herrn Florian Bauer,
          Mies-van-der-Rohe-Straße 1, 80807 München, Tel. 089/1243-0, Fax
          089/1243-4297, datenschutzbeauftragter@csu-bayern.de geltend machen.
        </p>
        <p>
          Sie haben – unbeschadet eines anderweitigen verwaltungsrechtlichen
          oder gerichtlichen Rechtsbehelfs – das Recht auf Beschwerde bei einer
          Aufsichtsbehörde, wenn Sie der Ansicht sind, dass die Verarbeitung der
          sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt
          (Art. 77 DSGVO). Sie können dieses Recht bei einer Aufsichtsbehörde in
          dem Mitgliedstaat ihres Aufenthaltsorts, ihres Arbeitsplatzes oder des
          Orts des mutmaßlichen Verstoßes geltend machen. In Bayern ist die
          zuständige Aufsichtsbehörde: Landesamt für Datenschutzaufsicht,
          Promenade 27 (Schloss), 91522 Ansbach, Telefon: +49 (0) 981 53 1300,
          Telefax: +49 (0) 981 53 98 1300, E-Mail: poststelle@lda.bayern.de.
        </p>
      </div>
    </Layout>
  )
}

export default DatenschutzPage
